@import 'constants';

.carouselNarrowScreen { display: none; }

.carousel {
    max-width: 944px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    height: 352px;
}

ul#slider {
    position: relative;
    margin: 0 auto;
    list-style: none;
    padding: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

ul#slider li { height: 0; }

ul#slider img {
    position: absolute;
    display: block;
    /* make image flexible */
    max-width: 100%;
    height: auto;
    width: auto\9; /* IE8*/
}

ul#carouselDots {
    position: absolute;
    width: 0 auto; left: 0; right: 0;  /* center an absolute positioned element */
    bottom: 0;
    list-style: none;
    padding: 0;
    margin: 0;
}

ul#carouselDots li {
    display: inline-block;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border: 2px solid $footer_bgColor;
    background: white;
    cursor: pointer;
}

ul#carouselDots li.active {
    background: $footer_bgColor;
}

#prevBtn,
#nextBtn {
    position: absolute;
    top: 0;
    background: transparent;
    border: 0;
    display: block;
    // line-height: 8em;
    height: inherit;
    // height: auto;
    width: 30%;
    margin: 0;
    padding: 0;
    cursor: pointer;

    p { height: 100%;
        font-size: 15em;
        color: rgba(0, 0, 0, 0.2);
    }
    p:hover { color: rgba(0, 0, 0, 0.5); }
}

#prevBtn {
    p { text-align: left;}
}

#nextBtn {
    right: 0;
    p { text-align: right; }
}